import React from "react"
import Helmet from "react-helmet"
import Layout, { Section, H2 } from "../../components/layout"
import Hero from "../../components/hero"
import Footer from "../../components/footer"
import backgroundImage from '../../images/articles/planning-during-the-caretaker-period.jpg';
import styled from "styled-components"

const ArticleInfo = styled.div`
    display: flex;
`

const DatePosted = styled.p`
    font-weight: bold;
`

const ReadTime = styled.p`
    margin-left: 1rem;
`

const ExternalLink = styled.a`
    color: #2A8236;
    text-decoration: underline;
`

const IndexPage = () => {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Polis Legal - Planning during the caretaker period</title>
                <meta name="description" content="The caretaker period starts when the four-year term of the Legislative Assembly (lower house of the Victorian parliament) expires (unless the Legislative Assembly is dissolved prior)." />
                <meta name="robots" content="index, follow" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Planning during the caretaker period" />
                <meta property="og:url" content="//polislegal.com.au/articles/planning-during-the-caretaker-period/" />
                <meta property="og:image" content={`//polislegal.com.au${backgroundImage}`} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="757" />
                <meta property="og:image:alt" content />
                <meta property="og:locale" content="en_GB" />
                <meta property="article:published_time" content="2022-10-31T09:00:00+00:00" />
            </Helmet>
            <Hero background={backgroundImage} title="Planning during the caretaker period" />
            <Section>
                <ArticleInfo>
                    <DatePosted>31 October 2022</DatePosted>
                    <ReadTime>5 min read</ReadTime>
                </ArticleInfo>

                <p>Those who work in the planning and environment sector will know the familiar rush for processes, decisions and approvals to be secured before the caretaker period starts prior to an election. But why? What is the caretaker period exactly and how does it affect planning and environment matters?</p>
                <H2>When is the caretaker period? </H2>
                <p>The caretaker period starts when the four-year term of the Legislative Assembly (lower house of the Victorian parliament) expires (unless the Legislative Assembly is dissolved prior). This year the caretaker period is scheduled to commence at 6pm on Tuesday, 1 November 2022.</p>
                <p>It ends when the outcome of the election is clear - possible outcomes and when the caretaker period ends for each one are below: </p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <p>The incumbent government is returned</p>
                            </td>
                            <td>
                                <p>Caretaker period ends when the Opposition concedes defeat, or vote counting confirms that sufficient seats have been won to form a majority government in the Legislative Assembly</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>The incumbent government doesn't win a majority of seats and needs to form a minority government</p>
                            </td>
                            <td>
                                <p>Caretaker period ends when public statements are made confirming agreement between parties/independents for the formation of a minority government; or if necessary, the Legislative Assembly holds a vote confirming that the formed minority government holds the support of the majority of the Assembly</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>The incumbent government is not returned</p>
                            </td>
                            <td>
                                <p>Caretaker period ends when the incoming government is sworn in</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <H2>What happens during the caretaker period? </H2>
                <p>The caretaker conventions commence operation. They are updated every election cycle and are published <ExternalLink target="_blank" href="https://www.vic.gov.au/guidelines-caretaker-conventions">online</ExternalLink>. Like with other processes involving conduct by members of Parliament and the public sector the caretaker conventions are just that - conventions, derived from constitutional practice and custom. They are not laws or regulations so do not have any legislative enforceability. Rather, a breach of the conventions by a public sector employee might demonstrate a breach of obligations under the Public Administration Act 2004 and various codes of conduct, which in turn might lead to disciplinary action and/or a finding of misconduct. </p>
                <p>Business-as-usual governance and public administration should continue and not be affected by the upcoming election. The public sector is required to continue to administer the government’s existing policies in their current form. That said, certain actions should not be taken so as not to bind or limit the actions of an incoming government and to maintain the political neutrality of the public sector.</p>
                <p>The conventions set out guidance around the following key actions, which should be avoided by the current government during the caretaker period: </p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <p>Avoid making or implementing major policy decisions that are likely to commit an incoming government or limit its freedom to act</p>
                            </td>
                            <td>
                                <p>Whether a particular policy decision is 'major' is a matter for judgement. Relevant considerations include:</p>
                                <ul>
                                    <li><p>the significance of the decision, in terms of policy and resources</p></li>
                                    <li><p>whether the decision is a matter of contention between the political parties during the election campaign</p></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Avoid making significant appointments</p>
                            </td>
                            <td>
                                <p>Relevant considerations as to whether an appointment is 'significant' include:</p>
                                <ul>
                                    <li><p>the seniority of the appointment</p></li>
                                    <li><p>the importance of the position</p></li>
                                    <li><p>whether the appointment is made under legislation</p></li>
                                </ul>
                                <p>Where it would be impractical or lead to adverse consequences to avoid making an appointment during the caretaker period, acting appointments or short term appointments should be considered</p>
                            </td>
                        </tr>
                        <tr>
                            <td><p>Avoid entering major contracts or undertakings</p></td>
                            <td>
                                <p>Relevant considerations as to whether a contract or undertaking is 'major' include:</p>
                                <ul>
                                    <li><p>the dollar value of the commitment</p></li>
                                    <li><p>whether the commitment implements or entrenches a policy, program or administrative structure which may be politically contentious</p></li>
                                    <li><p>whether the commitment requires Ministerial approval or a public tender process</p></li>
                                </ul>
                                <p>Where contracts have been entered into prior to the caretaker period, further related contracts may be entered into during the caretaker period if they are subsidiary to the ‘head contract’ or if penalties for breach may be incurred if not entered into</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Manage intergovernmental negotiations and visits</p>
                            </td>
                            <td>
                                <p>The current government should seek to avoid participation in intergovernmental negotiations or forums during the caretaker period, or should adopt an observer status</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>The conventions also set out some other general matters to note: </p>
                <ul>
                    <li><p>While the above actions should be avoided during the caretaker period if they can be, this is subject to any applicable legal obligations which cannot be avoided.</p></li>
                    <li><p>Ministers will usually sign only the necessary minimum of correspondence during the caretaker period. Therefore, some correspondence normally signed by Ministers may instead be signed by departmental officers during this time.</p></li>
                    <li><p>Heads of public sector bodies (which includes departments, such as DELWP) should establish engagement protocols with their Ministerial offices during the caretaker period. This includes establishing limited points of contact within the public sector body for engagement by Ministers and their officers. The public sector body Head should be copied into or notified of any engagement between Ministers or their officers, and Departmental staff, and they are responsible for ensuring that their public sector body observes the conventions during the caretaker period.</p></li>
                </ul>
                <H2>How does the caretaker period affect planning and environment matters?</H2>
                <p>Most planning and environment matters should not be affected by the caretaker period and adherence to the conventions.</p>
                <p>Local councils are usually the responsible authority for planning decisions so typical statutory planning approval decisions will continue. That said, local Councils are subject to their own conventions during the local election cycle, and what constitutes a ‘major’ planning decision will be different for a Council’s municipality as opposed to a major planning decision at a State level. </p>
                <p>In most cases the involvement of the Planning Minister and public sector bodies (eg, DELWP, EPA, VPA) in VCAT and Court proceedings should also continue as usual, and they would be subject to legal obligations as parties in an ongoing proceeding. The State and State agencies continue to be bound by the Model Litigant Guidelines during the caretaker period. </p>
                <p>Unless an identified matter of contention for the election, grants of Ministerial permits (eg, for residential towers) are unlikely to be seen as making ‘major’ policy decisions.Likewise for most planning scheme amendment approvals by the Planning Minister, particularly those which are not seeking to implement a major policy that any new government may not wish to proceed with (eg, LPP updates, heritage reviews, PSP roll outs, etc).That said, the reduced signing off on correspondence and reduced interaction between DELWP and the Planning Minister’s office during the caretaker period means that these sorts of planning approvals could slow down over this time. Reasons and decisions on major projects and proposals, including for Environment Effects Statements, could be deferred until after the caretaker period has ended. </p>
                <p>Transport infrastructure projects are always a hot election topic and this year is no different. The Suburban Rail Loop is a key project for the current government but the Opposition has said it will scrap it if elected. Works have commenced so some key contracts have clearly already been entered into, which may enable continuing negotiations for further agreements for this project during the caretaker period (which has been preceded by legislative amendments, an EES, business case and early works approval at record speed). Given this, the costs of scrapping the project by any incoming government will be enormous, and Victoria has already seen this before with the East West Link.</p>
                <p>All in all, it is business as usual for most, and despite some delays for certain projects and approvals the industry remains busy in the lead up to voting day.</p>
            </Section>
            <Footer />
        </Layout>
    )
}

export default IndexPage
